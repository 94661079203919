import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
const testimonialData = [
  {
    testimonialThumb: '/images/testimonial_1.jpeg',
    testimonialText:
      'Earendel was amazing! Great collaboration and communication and the quality of work greatly exceeded my expectations. The final product looked like the designs provided and they wrote very clean code that is very well formatted and easy to read. He went well and beyond what I was expecting putting effort into doing things the right way. I highly recommend you choose Earendel Technologies for your project because you won\'t be disappointed!',
    avatarName: 'Greg Pabst',
    avatarDesignation: 'CEO AT SEIZALARM LLC',
    ratings: '5',
  },
  {
    testimonialThumb: '/images/testimonial_2.jpeg',
    testimonialText:
      'Working with them was great. Team is very understanding of the goals I wanted to achieve with my app and made sure to get everything done perfectly. Developers were very skillful and understands right away how to make certain functions work with his programming. Communication was great as well since everything was done through email, zoom meetings and WhatsApp. Even though my Project was a little more complicated they still was able to make it work very effectively. Through various feedback circles we finished the project even faster than originally planned. I will definitely work with Earendel Technologies again and highly recommend him for your project as well!',
    avatarName: 'Tobias Fischer',
    avatarDesignation: 'CEO AT HEALTHDOC',
    ratings: '5',
  },
  {
    testimonialThumb: '/images/testimonial_3.jpeg',
    testimonialText:
      'This team has a very deep knowledge of the app world as well as being a great development service. Their wider knowledge and good nature means they will be able to suggest and provide more efficient solutions than expected. They are also very keen to produce high quality work.',
    avatarName: 'Richard Shaw',
    avatarDesignation: '',
    ratings: '5',
  },
  // {
  //   testimonialThumb: '/images/testimonial_1.jpeg',
  //   testimonialText:
  //     'I have tried other developers and agencies here on Fiverr before and they normally do ok or passing work but developer on this team went above and beyond what I was expecting. He captured every detail that I had explained to him and left comments in the code to make it easy to follow. I will definitely be working with him again in the future!! I would recommend him to anyone that is looking to build an app for an MVP product!',
  //   avatarName: 'Ahon Monsery',
  //   avatarDesignation: 'CEO AT TECH',
  //   ratings: '5',
  // },
];

export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
