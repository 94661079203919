import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Button from '../Button';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import { useState } from 'react';

const categories = [
  'All',
  'Services',
  'Process',
  'Communication',
  'Legal',
  'General',
  'Industries',
  'Mobile App Development',
  'UI/UX Design',
  'Website Development',
  'Security',
  'Project Management',
  'Cost',
  'Payment',
  'Support',
];

export default function FaqPage() {
  pageTitle('Frequently Asked Questions');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedCategory, setSelectedCategory] = useState('All');

  const handleCategoryChange = category => {
    setSelectedCategory(category); // reset selected accordion item when category changes
  }; 

  return (
    <>
      <PageHeading
        title="Frequently Asked Questions"
        bgSrc="/images/about_hero_bg.jpeg"
        pageLinkText="FAQ"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-4">
            <Div className="cs-faq_nav cs-radius_15">
              <h2 className="cs-faq_nav_title cs-m0">FAQ Category</h2>
              <Div className="cs-height_30 cs-height_lg_30" />
              <ul className="cs-list cs-style1 cs-mp0">
                {categories.map(category => (
                  <li key={category}>
                    <Div onClick={() => handleCategoryChange(category)}>
                      <Button
                        variant="cs-type2"
                        btnLink="/faq"
                        btnText={category}
                        icon={<Icon icon="material-symbols:content-copy-outline-rounded" />}
                      />
                    </Div>
                  </li>
                ))}
              </ul>
            </Div>
          </Div>
          <Div className="col-lg-7 offset-lg-1">
            <Spacing lg="0" md="40" />
            <Accordion category = { selectedCategory }  />
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
