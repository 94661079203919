import React from 'react';
import { useState } from 'react';
import Div from '../Div';
const accordionData = [
  {
    question: 'What services does your company provide?',
    answer: 'We offer mobile app development, UI/UX design, and website development services. Our team specializes in creating user-friendly and high-performance applications tailored to meet your business needs.',
    categories: ['General', 'Services'],
  },
  {
    question: 'How can I get a quote for my project?',
    answer: 'You can get a quote by contacting us through our website’s contact form, sending us an email, or calling us directly. Provide as many details as possible about your project so we can give you an accurate estimate.',
    categories: ['General', 'Communication'],
  },
  {
    question: 'What industries do you specialize in?',
    answer: 'We have experience in various industries including healthcare, finance, e-commerce, education, and entertainment. We adapt our solutions to fit the unique requirements of each sector.',
    categories: ['General', 'Industries'],
  },
  {
    question: 'What platforms do you develop mobile apps for?',
    answer: 'We develop mobile apps for both iOS and Android platforms. We can create native apps, cross-platform apps, or hybrid apps based on your needs.',
    categories: ['Mobile App Development', 'Services'],
  },
  {
    question: 'What is your mobile app development process?',
    answer: 'Our process includes discovery and planning, design, development, testing, and deployment. We also offer post-launch support and maintenance services.',
    categories: ['Mobile App Development', 'Process'],
  },
  {
    question: 'How long does it take to develop a mobile app?',
    answer: 'The development time varies based on the complexity and scope of the project. On average, it can take anywhere from a few weeks to several months.',
    categories: ['Mobile App Development', 'Process'],
  },
  {
    question: 'Can you help with app store submission?',
    answer: 'Yes, we assist with the entire app store submission process for both the Apple App Store and Google Play Store, ensuring your app meets all guidelines and requirements.',
    categories: ['Mobile App Development', 'Services', 'Process'],
  },
  {
    question: 'What is UI/UX design and why is it important?',
    answer: 'UI (User Interface) design focuses on the look and layout of an app or website, while UX (User Experience) design is about the overall feel and ease of use. Good UI/UX design enhances user satisfaction and engagement.',
    categories: ['UI/UX Design', 'General'],
  },
  {
    question: 'What is your UI/UX design process?',
    answer: 'Our design process includes research and analysis, wireframing, prototyping, visual design, and usability testing. We involve clients at every stage to ensure the design meets their vision and objectives.',
    categories: ['UI/UX Design', 'Process'],
  },
  {
    question: 'Can you redesign an existing app or website?',
    answer: 'Yes, we offer redesign services to improve the UI/UX of your existing app or website, making it more user-friendly and visually appealing.',
    categories: ['UI/UX Design', 'Services'],
  },
  {
    question: 'What technologies do you use for website development?',
    answer: 'We use a variety of technologies including HTML, CSS, JavaScript, React, Angular, Node.js, PHP, WordPress, and more. We select the best tools and frameworks based on the project requirements.',
    categories: ['Website Development', 'Services'],
  },
  {
    question: 'Do you offer e-commerce website development?',
    answer: 'Yes, we develop e-commerce websites with features like product listings, shopping carts, payment gateways, and order management. We ensure your e-commerce site is secure and user-friendly.',
    categories: ['Website Development', 'Services'],
  },
  {
    question: 'How do you ensure website security?',
    answer: 'We implement best practices for website security including SSL certificates, secure coding practices, regular updates, and security audits to protect your website from threats.',
    categories: ['Website Development', 'Security'],
  },
  {
    question: 'Do you provide website maintenance and support?',
    answer: 'Yes, we offer ongoing maintenance and support services to ensure your website remains up-to-date, secure, and performs optimally.',
    categories: ['Website Development', 'Services'],
  },
  {
    question: 'How do you manage projects and ensure timely delivery?',
    answer: 'We use agile project management methodologies to ensure flexibility and timely delivery. Regular updates and milestone tracking help us stay on schedule.',
    categories: ['Project Management', 'Process'],
  },
  {
    question: 'How do you communicate with clients during the project?',
    answer: 'We maintain regular communication through emails, phone calls, video meetings, and project management tools. Clients are kept informed about progress and can provide feedback throughout the project.',
    categories: ['Project Management', 'Communication'],
  },
  {
    question: 'Can I make changes to the project after it has started?',
    answer: 'Yes, changes can be made during the project. We follow an iterative approach and incorporate feedback. However, significant changes may affect the timeline and budget.',
    categories: ['Project Management', 'Process'],
  },
  {
    question: 'How much does it cost to develop a mobile app/website?',
    answer: 'The cost varies depending on the complexity and features of the app or website. We provide a detailed estimate after understanding your requirements.',
    categories: ['Cost'],
  },
  {
    question: 'What are your payment terms?',
    answer: 'Our payment terms typically involve an initial deposit followed by milestone-based payments. We can discuss and agree on a payment plan that suits both parties.',
    categories: ['Cost', 'Payment'],
  },
  {
    question: 'Do you offer post-launch support?',
    answer: 'Yes, we offer various post-launch support packages to help you with updates, maintenance, and any issues that may arise after the launch.',
    categories: ['Services', 'Support'],
  },
];

export default function Accordion({ category }) {
  const [selected, setSelected] = useState(0);
  const handelToggle = index => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  const filteredData = accordionData.filter(item => 
    category === 'All' || item.categories.includes(category)
  );

  return (
    <Div className="cs-accordians cs-style1">
      {filteredData.map((item, index) => (
        <Div
          className={`cs-accordian ${selected === index ? 'active' : ''}`}
          key={index}
        >
          <Div
            className="cs-accordian_head"
            onClick={() => handelToggle(index)}
          >
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg
                width={15}
                height={8}
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>
            </span>
          </Div>
          <Div className="cs-accordian_body">
            <Div className="cs-accordian_body_in">{item.answer}</Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
}
